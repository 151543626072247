<template>
	<div class="rank">
		<div class="rank-top">
			<p class="rank-title">热门景区推荐</p>
			<div class="rank-city">

				<div class="city-line"></div>
				<div class="city-dot"></div>
				<div class="city-name">
					<div class="city-text"><span>郑</span></div>
					<div class="city-text"><span>州</span></div>
				</div>
				<div class="city-dot"></div>
				<div class="city-line"></div>
			</div>

		</div>

		<div class="rank-list">
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList" >
				<div class="rank-item" v-for="(item,index) in goodList" @click="indexDetails(item.id)">

					<img :src="item.image" alt="" class="rank-item-image">

					<div class="rank-item-content">
						<p class="rank-item-title">{{item.name}}</p>
						<div class="rank-item-rate">
							<!-- <van-rate v-model="item.score" :size="15" color="#FB9A00" allow-half void-icon="star" void-color="#eee" /> -->
							<span class="rank-item-mark">{{item.score}}分</span>
							<span class="rank-item-comment">{{item.talk_count}}条评论</span>
						</div>
						<!-- <div class="rank-item-classify">
							<span>水上乐园</span>
							<span>新密</span>
						</div> -->
						<div class="rank-item-bottom">
							<div class="rank-item-info">
								<span>需提前预约</span>
							</div>
							<div class="rank-item-price">
								<!-- <span>门票</span> -->
								<span class="price-text">￥{{item.price}}</span>
								<span>起</span>
							</div>
						</div>
						<div class="rank-item-address">
							<p>地址：{{item.address}}</p>
						</div>
					</div>


				</div>
				<!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
			</van-list>
		</div>

	</div>
</template>
<script>
	import * as index from '@/api/index'

	import {
		reactive
	} from 'vue';

	import {
		toUrl
	} from '@/utils/tools'
	import {
		Dialog,
		Toast
	} from 'vant';
	export default {

		data() {

			return {
				goodList: [],
				loading: false,
				finished: false,
				active: 2,
				showIndexBar: false,
				rateValue: 3,
				value: '',
				options: '',
				nullTip: '空空如也~',
				limit: 20, // 每页条数
				page: 1, // 页码
				type: 1,
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				value2: 0,
				value1: 0,
				dataList: '',
				keyword: '',
			}
		},
		mounted() {
			this.getIndexList();
		},
		computed: {
		    location: function () {
				return this.$store.state.app.latAndLng
		    },
		   
		},
		methods: {
			goscenicSpotRank() {
				this.$router.push({
					path: 'scenicSpotRank'
				})
			},
			onSearch(val) {
				this.keyword = val
				this.page = 1
				this.getIndexList()
				document.documentElement.scrollTop = 0
			},
			goMap() {
				this.$router.push({
					path: '/h5/map'

				})
			},
			indexDetails(id) {
				console.log(id)
				this.$router.push({
					path: "/h5/commDetail",
					query: {
						id: id
					}
				})
			},

			//获取列表
			getIndexList() {
				console.log(this.location)
				let params = {}
				params.page = this.page
				params.limit = this.limit
				params.keyword = this.keyword
				params.lng = this.location.longitude
				params.lat =  this.location.latitude
				console.log(params)
				index.getScenic(params).then((res) => {
					console.log(res)
					const rows = res.data.list
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						if (this.page == 1) {

							this.dataStatus = false
							this.$toast.clear()
						}
						return
					} else {
						this.dataStatus = true
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodList = rows
					} else {
						this.goodList = this.goodList.concat(rows)
					}
					// console.log(this.goodList)
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getIndexList()
			},
		}
	}
</script>
<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.rank {
		.rank-top {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 200px;
			background-size: 100% 100%;
			background-image: url('https://t1-q.mafengwo.net/s12/M00/A5/1A/wKgED1wBUm-ADJn6AB-5Scwfdqg27.jpeg?imageMogr2%2Fthumbnail%2F%21690x450r%2Fgravity%2FCenter%2Fcrop%2F%21690x450%2Fquality%2F90%7Cwatermark%2F1%2Fimage%2FaHR0cHM6Ly9iMS1xLm1hZmVuZ3dvLm5ldC9zMTEvTTAwLzQ0LzlCL3dLZ0JFRnNQNVJ5QUR2N3BBQUFIWlpVUFJsUTk5MC5wbmc%3D%2Fgravity%2FSouthEast%2Fdx%2F10%2Fdy%2F11');

			.rank-title {
				color: #FFFFFF;
				font-size: 38px;
				font-weight: bold;
			}

			.rank-city {
				display: flex;
				align-items: center;
				width: 50%;
				justify-content: space-around;

				.city-line {
					height: 1px;
					width: 40px;
					background-color: #FFFFFF;
				}

				.city-dot {
					width: 5px;
					height: 5px;
					background-color: #FFFAEA;
					transform: rotate(-45deg);
				}

				.city-name {
					display: flex;

					.city-text {
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						border: 1px solid #FFFFFF;
						font-size: 11px;
						width: 23px;
						height: 23px;
						box-sizing: border-box;

						span {
							color: #FFFFFF;
							line-height: 23px;

						}
					}
				}
			}
		}

		.rank-list {
background-color: #F6F6F6;
			padding: 10px;

			.rank-item {
				background-color: #FFFFFF;
				border-radius: 5px;
				box-sizing: border-box;
				margin-bottom: 10px;
				display: flex;
				padding: 15px;
				// justify-content: space-between;

				.rank-item-image {
					width: 107px;
					height: 87px;
					border-radius: 5px;
				}

				.rank-item-content {
					margin-left: 10px;
					display: flex;
					flex-direction: column;
width: calc(100% - 112px);
					.rank-item-title {
						font-size: 17px;
						color: #1A1A1A;
						font-weight: bold;
						margin: 0;
						line-height: 17px;
					}

					.rank-item-rate {
						display: flex;
						align-items: center;
						margin-top: 12px;

						.rank-item-mark {
							color: #FC6228;
							font-size: 10px;
							line-height: 10px;

						}

						.rank-item-comment {
							color: #666666;
							font-size: 12px;
							line-height: 12px;
							margin-left: 15px;
						}
					}

					.rank-item-classify {
						display: flex;
						align-items: center;
						margin-top: 10px;

						span {
							color: #999999;
							font-size: 12px;
							margin-right: 10px;
							line-height: 12px;
						}

						;
					}

					.rank-item-bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 10px;

						.rank-item-info {

							width: 70px;
							height: 16px;
							font-size: 10px;
							line-height: 16px;
							color: #FC6228;
							border: 1px solid #FC6228;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.rank-item-price {
							display: flex;
							align-items: center;
							font-size: 10px;
							color: #666666;

							.price-text {
								font-size: 17px;
								color: #FC6228;
								line-height: 17px;
								font-weight: bold;
							}
						}
					}

					.rank-item-address {
						font-size: 10px;
						margin-top: 10px;
						color: #666666;
					}
				}
			}
		}
	}
</style>
